import styled from 'styled-components';
import { h6Styles } from '@luigiclaudio/ga-baseline-ui/typography';
import { rem } from 'polished';

const CategoryListItemAmountsLabel = styled.span.attrs(() => ({
    colorValue: 'muted',
}))`
    ${h6Styles}
    margin-right: ${rem(16)};
`;

export default CategoryListItemAmountsLabel;
