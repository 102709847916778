import React, { useState, useEffect, useContext, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { Col, Row } from '@luigiclaudio/ga-baseline-ui/layout';
import { H1, P } from '@luigiclaudio/ga-baseline-ui/typography';
import { FormButtonGroup, FormRadioButton } from '@luigiclaudio/ga-baseline-ui/form';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import VirtualizedCategoryListList from './VirtualizedCategoryListList';
import CategoryListSortFiltersContainer from './CategoryListSortFiltersContainer';
import CategoryListListFadeContainer from './CategoryListListFadeContainer';
import CategoryListSortFilterContainer from './CategoryListSortFilterContainer';
import CategoryListSortFilterLabel from './CategoryListSortFilterLabel';
import BrandRankingsHeader from './CategoryListHeader';
import CategoryListIntro from './CategoryListIntro';
import useAppUser from '../../../services/useAppUser';
import appConfig from '../../../data/appConfig.json';
import categoryUtils from '../../../utils/categoryUtils';
import categoryListPropTypes from '../../../prop-types/categoryListPropTypes';

const CategoryListContent = ({ categoryApi }) => {
    if (!categoryApi) {
        return null;
    }

    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const { values } = useFormikContext();
    const { userActiveGroups } = useAppUser();
    const headerBind = useRef();

    const { sortByItems } = values;
    const { groups } = appConfig;

    const [categoryListByUser, setCategoryListByUser] = useState([]);
    const [currentCategoryListByUser, setCurrentCategoryListByUser] = useState();
    const [categoryListHeaderHeight, setCategoryListHeaderHeight] = useState();

    useEffect(() => {
        if (categoryApi) {
            setCategoryListByUser(
                userActiveGroups
                    ? categoryUtils.categoryListByUserPreferences({
                          categoryList: categoryApi,
                          activeGroups: userActiveGroups,
                      })
                    : categoryUtils.categoryListByUserPreferences({
                          categoryList: categoryApi,
                          activeGroups: groups,
                      }),
            );
        }
    }, [categoryApi, userActiveGroups]);

    useEffect(() => {
        if (categoryListByUser) {
            if (sortByItems === 'highToLow') {
                setCurrentCategoryListByUser(
                    categoryUtils.sortCategoriesByProductsAmountHightToLow(categoryListByUser),
                );
            }
            if (sortByItems === 'lowToHigh') {
                setCurrentCategoryListByUser(
                    categoryUtils.sortCategoriesByProductsAmountLowToHigh(categoryListByUser),
                );
            }
        }
    }, [categoryListByUser, sortByItems]);

    const checkBrandRankingsHeaderHeight = () => {
        setCategoryListHeaderHeight(headerBind.current.getBoundingClientRect().height);
    };

    useEffect(() => {
        setCategoryListHeaderHeight(headerBind.current?.getBoundingClientRect().height);
    }, [headerBind.current]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', checkBrandRankingsHeaderHeight);
            return () => window.removeEventListener('resize', checkBrandRankingsHeaderHeight);
        }
        return null;
    }, []);

    const sortHighToLowIcon = theme.site.sort.icon.highToLow;
    const sortLowToHighIcon = theme.site.sort.icon.lowToHigh;
    const sortIconSize = theme.site.sort.iconSize;

    return (
        <Row justifyContent="center">
            <Col xxs={30} lg={12}>
                <Row justifyContent="center">
                    <Col xxs={30} lg={22}>
                        <BrandRankingsHeader ref={headerBind}>
                            <CategoryListIntro>
                                <H1>
                                    <FormattedMessage
                                        id="categoryList.title"
                                        values={{
                                            amount: intl.formatNumber(categoryListByUser.length),
                                        }}
                                    />
                                </H1>
                                <P>
                                    <FormattedMessage id="categoryList.subtitle" />
                                </P>
                            </CategoryListIntro>
                            <CategoryListSortFiltersContainer>
                                <CategoryListSortFilterContainer>
                                    <CategoryListSortFilterLabel colorValue="muted">
                                        <FormattedMessage id="categoryList.sortByItems.label" />
                                    </CategoryListSortFilterLabel>
                                    <Field
                                        component={FormButtonGroup}
                                        id="sortByItems"
                                        name="sortByItems"
                                        buttonSkin="muted"
                                        buttonSize="sm"
                                        role="radiogroup"
                                        title={intl.formatMessage({
                                            id: 'categoryList.sortByItems.label',
                                        })}
                                    >
                                        <Field
                                            component={FormRadioButton}
                                            id="sortByItems"
                                            name="sortByItems"
                                            value="highToLow"
                                            aria-label={intl.formatMessage({
                                                id:
                                                    'categoryList.sortByItems.highToLow.button.label',
                                            })}
                                        >
                                            <GaIcon icon={sortHighToLowIcon} size={sortIconSize} />
                                        </Field>
                                        <Field
                                            component={FormRadioButton}
                                            id="sortByItems"
                                            name="sortByItems"
                                            value="lowToHigh"
                                            aria-label={intl.formatMessage({
                                                id:
                                                    'categoryList.sortByItems.lowToHigh.button.label',
                                            })}
                                        >
                                            <GaIcon icon={sortLowToHighIcon} size={sortIconSize} />
                                        </Field>
                                    </Field>
                                </CategoryListSortFilterContainer>
                            </CategoryListSortFiltersContainer>
                        </BrandRankingsHeader>
                    </Col>
                </Row>
            </Col>
            <Col xxs={30} lg={16}>
                <Row justifyContent="center">
                    <Col xxs={30} lg={22}>
                        <CategoryListListFadeContainer headerOffset={categoryListHeaderHeight}>
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <VirtualizedCategoryListList
                                            itemWidth={width}
                                            itemHeight={height}
                                            itemCount={currentCategoryListByUser.length}
                                            categoryList={currentCategoryListByUser}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </CategoryListListFadeContainer>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

CategoryListContent.propTypes = {
    categoryApi: categoryListPropTypes.categoryList,
};

CategoryListContent.defaultProps = {
    categoryApi: undefined,
};

export default CategoryListContent;
