import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Loader, useHasMounted, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Col, Container, Row, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import useAppUser from '../services/useAppUser';
import CategoryListContent from '../components/app/categoryList/CategoryListContent';
import CategoryListContainer from '../components/app/categoryList/CategoryListContainer';
import SiteFooter from '../components/pageLayout/SiteFooter';
import categoryUtils from '../utils/categoryUtils';

const CategoriesPage = () => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const { isLoadingSubscription, isErrorSubscription, username } = useAppUser();

    const [categoriesApi, setCategoriesApi] = useState();

    useEffect(() => {
        const getCategoriesGroups = async () => {
            const response = await categoryUtils.categoriesGroupsApi();
            setCategoriesApi(response);
        };
        getCategoriesGroups();
    }, []);

    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    if (isLoadingSubscription && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if ((isEmpty(categoriesApi) || !categoriesApi) && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <TransitionItem appear inProp>
                    <CategoryListContainer>
                        <SiteContentWrapper>
                            <Container>
                                <Row justifyContent="center" isFullHeight>
                                    <Col xxs={30} lg={30}>
                                        <Formik
                                            initialValues={{
                                                sortByItems: 'highToLow',
                                            }}
                                        >
                                            {() => {
                                                return (
                                                    <Form noValidate>
                                                        <CategoryListContent
                                                            categoryApi={categoriesApi}
                                                        />
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Container>
                        </SiteContentWrapper>
                    </CategoryListContainer>
                </TransitionItem>
            }
            footer={<SiteFooter />}
        />
    );
};

export default CategoriesPage;
