import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { GatsbyA } from '@luigiclaudio/ga-baseline-ui/typography';
import CategoryTitle from '../../CategoryTitle';
import BrandRankingsItemContainer from './CategoryLisItemContainer';
import BrandRankingsItemAmountsContainer from './CategoryListItemAmountsContainer';
import BrandRankingsItemAmountsLabel from './CategoryListItemAmountsLabel';
import BrandRankingsItemTitleContainer from './CategoryListItemTitleContainer';
import BrandRankingsItemTitle from './CategoryListItemTitle';
import appConfig from '../../../../data/appConfig.json';

const CategoryListItem = ({ title, totalCount, to, onClick, cardWidth, isLast }) => {
    const intl = useIntl();
    const { countries } = appConfig;

    const toLink = `/category-${to}-${countries[0]}`;

    return (
        <BrandRankingsItemContainer isLast={isLast}>
            <BrandRankingsItemTitle>
                <BrandRankingsItemTitleContainer cardWidth={cardWidth}>
                    <GatsbyA color="dark" to={toLink} onClick={onClick}>
                        <CategoryTitle>{title}</CategoryTitle>
                    </GatsbyA>
                </BrandRankingsItemTitleContainer>
            </BrandRankingsItemTitle>
            <BrandRankingsItemAmountsContainer>
                <BrandRankingsItemAmountsLabel>
                    <FormattedMessage
                        id="brandCard.itemsAmount"
                        values={{ amount: intl.formatNumber(totalCount) }}
                    />
                </BrandRankingsItemAmountsLabel>
            </BrandRankingsItemAmountsContainer>
        </BrandRankingsItemContainer>
    );
};

CategoryListItem.propTypes = {
    title: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
    to: PropTypes.string.isRequired,
    cardWidth: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    isLast: PropTypes.bool,
};

CategoryListItem.defaultProps = {
    onClick: () => {},
    isLast: false,
};

export default CategoryListItem;
