import styled from 'styled-components';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const CategoryListListContainer = styled.div`
    overflow-y: scroll;
    position: relative;

    ${scrollbarStyles}
`;

export default CategoryListListContainer;
