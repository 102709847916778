import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import { transparentize } from 'polished';
import { css } from 'styled-components';

const listFadeContainerStyles = () => css`
    &:after,
    &:before {
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &:after {
        background-image: linear-gradient(
            to bottom,
            ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
            ${({ theme }) => theme.site.stage.backgroundColor} 50%
        );
        bottom: -2px;
    }

    ${media.md`    
        &:before {
            background-image: linear-gradient(
                to top,
                ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
                ${({ theme }) => theme.site.stage.backgroundColor} 50%
            );
            top: -2px;
        }
    `}
`;

export default listFadeContainerStyles;
