import styled from 'styled-components';
import { h2Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const CategoryListItemTitle = styled.span`
    ${h2Styles}
    margin-top: 0;
    text-transform: capitalize;
`;

export default CategoryListItemTitle;
