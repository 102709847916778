import { rem } from 'polished';
import styled from 'styled-components';
import { cssHelpers } from '@luigiclaudio/ga-baseline-ui/helpers';

const CategoryListItemTitleContainer = styled.span`
    ${cssHelpers.textOverflow}
    display: block;
    width: ${({ cardWidth }) => rem(cardWidth - 32)};
`;

export default CategoryListItemTitleContainer;
