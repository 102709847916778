import PropTypes from 'prop-types';

const categoryListItem = PropTypes.shape({
    title: PropTypes.string,
    availableGroups: PropTypes.arrayOf(PropTypes.string),
    productsByGroup: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.string,
            totalCount: PropTypes.number,
        }),
    ),
    totalCount: PropTypes.number,
});

const categoryList = PropTypes.arrayOf(categoryListItem);

export default { categoryListItem, categoryList };
