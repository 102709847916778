import { rem } from 'polished';
import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import listFadeContainerStyles from '../../../styles/listFadeContainerStyles';

const CategoryListListFadeContainer = styled.div`
    height: ${({ theme, headerOffset }) =>
        `calc(100vh - ${Math.ceil(
            theme.header.height +
                theme.site.brandSearch.height +
                theme.site.stage.paddingTop +
                headerOffset,
        )}px)`};
    padding: 0 ${rem(16)};
    position: relative;

    ${media.md`
        height: ${({ theme }) =>
            `calc(90vh - ${rem(
                theme.header.height + theme.site.brandSearch.height + theme.site.stage.paddingTop,
            )})`};
    `}

    ${listFadeContainerStyles}
`;

export default CategoryListListFadeContainer;
