import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const CategoryListSortFiltersContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${media.sm`
        flex-direction: row
    `}
`;

export default CategoryListSortFiltersContainer;
